// NAV MOBILE TOGGLE
let navToggle = document.querySelector('.nav-toggle');
let menuWrapper = document.querySelector('.header__mobile-navs');

navToggle.addEventListener('click', function () {

  if (navToggle.classList.contains('is-active')) {
    navToggle.classList.remove('is-active');
    menuWrapper.classList.remove('is-expanded');
    document.body.classList.remove("menu-open");
    this.setAttribute('aria-expanded', 'false');

  } else {
    navToggle.classList.add('is-active');
    menuWrapper.classList.add('is-expanded');
    document.body.classList.add("menu-open");
    this.setAttribute('aria-expanded', 'true');
  }
});

// JS TOGGLE/EXPAND
const toggles = document.querySelectorAll('.nav__sub-toggle');

for (let a of toggles) {
    a.addEventListener('click', function (event) {

      if (a.classList.contains('nav__sub-toggle--active')) {
        a.classList.remove('nav__sub-toggle--active');
        a.setAttribute('aria-expanded', 'false');
        event.preventDefault();

      } else {
        a.classList.add('nav__sub-toggle--active');
        a.setAttribute('aria-expanded', 'true');
        event.preventDefault();
      }
    });
}

let leftNav = () => {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  var d = $(document).scrollTop();

  $(".nav__submenu").each(function () {
    let p = $(this).offset();

    //horizontal
    if ((p.left + $(this).width()) > $(window).innerWidth()) {
      $(this).addClass('menu-left');
    }
  });
}

$(function () {
  leftNav();
  $('#skip-to-navigation a').on('focus', function () {
    $(this).parents('li').addClass('focussed');
  }).on('blur', function () {
    $(this).parents('li').removeClass('focussed');
  });

  $(window).scroll(function () {
    $(".hero-text").css("opacity", 1 - $(window).scrollTop() / 150);
  });
});
// require('waypoints/lib/jquery.waypoints.min');

if ($('.rellax').length > 0) {
  var rellax = new Rellax('.rellax', {
    speed: -2,
    center: false,
    wrapper: null,
    round: true,
    vertical: true,
    horizontal: true
  });
}

$(window).on('resize', function () {
  $('.nav__submenu').removeClass('menu-left');
  leftNav();
});


$(document).ready(function () {

  let plannerLocalStorageName = "tripPlanner", plannedTrips = [];
  if (localStorage.getItem(plannerLocalStorageName)) {
    plannedTrips = JSON.parse(localStorage.getItem(plannerLocalStorageName));
  }

  if (localStorage.getItem('plannerCount')) {
    let plannerCount = localStorage.getItem('plannerCount')
    document.getElementsByClassName('js--plannerCount')[0].style.display = 'flex';
    document.getElementsByClassName('trip-planner__btn')[0].classList.remove('disabled');
    document.getElementsByClassName('js--plannerCount')[0].innerHTML = plannerCount
  } else {
    document.getElementsByClassName('js--plannerCount')[0].style.display = 'none';
    document.getElementsByClassName('trip-planner__btn')[0].classList.add('disabled');
  }

});


$('#show-see-and-do').on('mouseenter', function () {
  $('#see-and-do-nav').addClass('visible');
  $(this).addClass('active');
  $('.hero-text-wrapper').addClass('faded');
});
$('#see-and-do-nav').on('mouseleave', function () {
  $('#see-and-do-nav').removeClass('visible');
  $('#show-see-and-do').removeClass('active');
  $('.hero-text-wrapper').removeClass('faded');
});

$('.shutter-title').on('click',
  function () {
    $(this).toggleClass('active');
    $(this).closest('.component__content-rows__row').find('.shutter-content').attr('aria-expanded', function (i, attr) {
      return attr == 'true' ? 'false' : 'true'
    });
  }
);

$('.translate--item').on('click',
  function () { $(this).toggleClass('active'); }
);


var $grid = $('.grid').isotope({
  // main isotope options
  itemSelector: '.grid-item',
  // set layoutMode
  layoutMode: 'masonry',
  filter: '*'
})
jQuery(window).on('load', function () {
  var $ = jQuery;
  var $grid = $('.grid').isotope({
    // main isotope options
    itemSelector: '.grid-item',
    // set layoutMode
    layoutMode: 'masonry'
  })
});

var $filters = $('.isotope-filters').on('click', 'button', function () {
  var $this = $(this);
  var filterFns = {
  };
  var filterValue = $(this).attr('data-filter');
  if ($this.is('.is-checked')) {
    // uncheck
    filterValue = '*';
  } else {
    filterValue = $this.attr('data-filter');
    $filters.find('.is-checked').removeClass('is-checked');
  }
  $this.toggleClass('is-checked');

  filterValue = filterFns[filterValue] || filterValue;
  $grid.isotope({ filter: filterValue });
});

$(document).ready(function() {
  $(document).on('click', 'a[rel$="external"]', function() {
  $(this).attr('target', "_blank");
  });
  $(document).on('click', 'a[href$=".pdf"]', function() {
  $(this).attr('target', "_blank");
  });
  $(document).on('click', "a[href^='http:']:not([href*='" + window.location.host + "'])", function() {
  $(this).attr("target", "_blank");
  });
  $(document).on('click', "a[href^='https:']:not([href*='" + window.location.host + "'])", function() {
  $(this).attr("target", "_blank");
  });
});


if ($('#banner__image').length > 0) {
    imagesLoaded( '#banner__image', function() {
        document.getElementById("banner__wrapper").className += " animate-initiate";
    });
}

var lazyLoadInstance = new LazyLoad({
    elements_selector: ".js-lazy"
});

 
